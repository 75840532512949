import React from 'react';
import { Link, animateScroll as scroll } from "react-scroll";

function NavBar() {
	return (
		<nav className="navbar navbar-expand-lg navbar-light">
			<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span className="navbar-toggler-icon"></span>
			</button>
			<div className="collapse navbar-collapse" id="navbarSupportedContent">
				<ul className="navbar-nav mr-auto container">

					<li className="nav-item col-lg-2 col-md-2 col-sm-2 col-xs-2">
						<Link className="nav-link" to="about" spy={true} smooth={true} offset={-50} duration={500}>
							Despre
		        		</Link>
					</li>
					<li className="nav-item col-lg-2 col-md-2 col-sm-2 col-xs-2">
						<Link className="nav-link" to="services" spy={true} smooth={true} offset={-50} duration={500}>
							Servicii
				        </Link>
					</li>
					<li className="nav-item col-lg-2 col-md-2 col-sm-2 col-xs-2">
						<Link className="nav-link" to="activities" spy={true} smooth={true} offset={-50} duration={500}>
							Activități
				        </Link>
					</li>	
					<li className="nav-item col-lg-2 col-md-2 col-sm-2 col-xs-2">
						<Link className="nav-link" to="gmap" spy={true} smooth={true} offset={-50} duration={500}>
							Hartă
		        		</Link>
					</li>				
					<li className="nav-item col-lg-2 col-md-2 col-sm-2 col-xs-2">
						<Link className="nav-link" to="footer" spy={true} smooth={true} offset={-50} duration={500}>
							Contact
		        		</Link>
					</li>
				</ul>
			</div>
		</nav>
	);
}

export default NavBar;