import React, {Component} from 'react';
import PropTypes from 'prop-types';
import shouldPureComponentUpdate from 'react-pure-render/function';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {PlacesStyle} from './PlacesStyle';

export default class Places extends Component {
  static propTypes = {    
  };

  static defaultProps = {};

  shouldComponentUpdate = shouldPureComponentUpdate;

  constructor(props) {
    super(props);
  }
///* {this.props.text} */
  render() {
    return (
       <div style={PlacesStyle} className={this.props.isMain ? 'gmap-items main-icon' : 'gmap-items'}>
          <FontAwesomeIcon icon={this.props.icon} />
       </div>
    );
  }
}