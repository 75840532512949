import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faBed, faUtensils, faHiking } from '@fortawesome/free-solid-svg-icons';


function About() {
  return (
    <div className="container about" id="about">
      <h1>
        Pensiunea Casa Brădeană
        <FontAwesomeIcon className="font-awesome daisies" icon={faSun} />
        <FontAwesomeIcon className="font-awesome daisies" icon={faSun} />
        <FontAwesomeIcon className="font-awesome daisies" icon={faSun} />
      </h1>
      <div class="row">
        <div class="col-sm-2"></div>
        <div class="col-sm-8">
          <p>Pensiunea agroturistică “Casa Brădeana”, clasificată cu 3 margarete,este
              situată în localiatatea Albac, județul Alba cu intrare din
              DN1R care face legătura Albac-Huedin. Ideea acestei pensiuni s-a născut din
              dragostea față de locurile de poveste în care locuim și din dorința de a ne face
              oaspeții să se îndrăgostească de zona Munților Apuseni. Avantajul nostru îl
              reprezintă gospodăria proprie din care cei ce ne calcă pragul vor putea gusta
              gemuri de afine, de zmeură și de căpșuni, produse tradiționale din carne de
              porc, lapte proaspăt muls, ouă de casă etc.
          </p>
          <p>
            Peisajele noastre de o frumusețe năucitoare, amabilitatea gazdelor, produsele naturale oferite cu
            drag vor face ca oaspeții ce ne vor vizita să revină cu plăcere la pensiunea noastră.
          </p>
        </div>
        <div class="col-sm-2"></div>

      </div>
      {/* <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
            <FontAwesomeIcon className="font-awesome" icon={ fa-sun }/>
            <h3>Camere spațioase și confortabile pentru odihnă relaxantă</h3>
            <p>
              Camerele spațioase sunt o continuare a Naturii, prin cromatica abordată și decorațiunile interioare. Spațioase și confortabile, camerele dispun de terase, saltele ortopedice și băi complet utilate, cu produse de îngrijire.
            </p>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
            <FontAwesomeIcon className="font-awesome" icon={ faUtensils }/>
            <h3>Mâncare naturală din ingrediente naturale</h3>
            <p>
              Este natural să vă oferim cele mai gustoase preparate pregătite cu ingrediente alese cu grijă. Cele mai proaspete mâncăruri cu carne sau vegetariene delicioase, pe toate le veți descoperi pe parcursul sejurului dvs.
            </p>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
            <FontAwesomeIcon className="font-awesome" icon={ faHiking }/>
            <h3>Activități și excursii în natură</h3>
            <p>
              Activitățile în natură includ drumeții montane sau trekking iar când vorbim de excursii,sunt multe peisaje care prin frumusețea lor sunt nemaipomenite, de la Piatră Grăitoare la Colțul Vulturului, peisaje de poveste.
            </p>
          </div>
        </div> */}
    </div>
  );
}


export default About;