const K_WIDTH = 40;
const K_HEIGHT = 40;

const PlacesStyle = {
  // initially any map object has left top corner at lat lng coordinates
  // it's on you to set object origin to 0,0 coordinates
  position: 'absolute',
  width: K_WIDTH,
  height: K_HEIGHT,
  left: -K_WIDTH / 2,
  top: -K_HEIGHT / 2,

  border: '3px solid #9d9272',
  borderRadius: K_HEIGHT,
  backgroundColor: 'white',
  textAlign: 'center',
  color: '#9d9272',
  fontSize: 16,
  fontWeight: 'bold',
  padding: 4
};

export {PlacesStyle};