import React, { Component } from 'react';
import NavBar from './NavBar/NavBar';
import Banner from './Banner/Banner';
import About from './About/About';
import Services from './Services/Services';
import ScrollTop from './ScrollTop/ScrollTop';
import Footer from './Footer/Footer';
import GMap from './GMap/GMap';

class App extends Component {
	render() {
		return (
			<div className="container-fluid">
				<NavBar />
				<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 banner">
					<Banner />
				</div>
				<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
					<div class="row">
						<div class="col-xs-2 col-sm-2"></div>
						<div class="col-xs-8 col-sm-8">
							<p className="banner-call">Pentru rezervări va rugăm sunați la numărul <a href="tel:0740270243">0740.270.243</a></p>
						</div>
					</div>
				</div>
				<div className="hero-spacing"></div>
				<About />
				<div className="hero-spacing"></div>
				<Services />
				<div className="hero-spacing"></div>
				<GMap></GMap>				
				<ScrollTop />
				{/* <Footer /> */}
			</div>

		);
	}
}

export default App;
