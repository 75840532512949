import React from 'react';

function Banner() {
  return (
  	<div class="heroContainer">
  		<img src = '/images/main2.jpg' alt="Banner" className="banner-image"/>  			
  	</div>
  	);
}

export default Banner;