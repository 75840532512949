import React from 'react';
import { Link, animateScroll as scroll } from "react-scroll";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faAddressBook, faPhoneSquare, faMapMarkedAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';

function Footer() {
  return (
    <div className="container-fluid footer" id="footer">
      <div className="row">
        <div className="col-lg-12">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <ul className="f-contact">
                  <li>
                    <a href="https://www.facebook.com/" className="icon-text">
                      <FontAwesomeIcon icon={faFacebook} /><span>CasaBradeana</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="icon-text">
                      <FontAwesomeIcon icon={faAddressBook} /><span>Persoana de contact:</span><span>Iuliana Scrob</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="icon-text">
                      <FontAwesomeIcon icon={faPhoneSquare} /><span>Telefon:</span><span>0740.270.243</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="icon-text">
                      <FontAwesomeIcon icon={faMapMarkedAlt} /><span>Adresa:</span><span>Strada Principala, nr. 7, Albac, judetul Alba</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="icon-text">
                      <FontAwesomeIcon icon={faEnvelope} /><span>E-Mail:</span> <span> casa.bradeana@gmail.com</span>
                    </a>
                  </li>
                </ul>
              </div>              
              <ul>
                <li className="nav-item col-lg-3 col-md-3 col-sm-3 col-xs-3">
                  <Link className="nav-link" to="about" spy={true} smooth={true} offset={-50} duration={500}>
                    Despre
                  </Link>
                </li>
                <li className="nav-item col-lg-3 col-md-3 col-sm-3 col-xs-3">
                  <Link className="nav-link" to="services" spy={true} smooth={true} offset={-50} duration={500}>
                    Servicii
                  </Link>
                </li>
                <li className="nav-item col-lg-3 col-md-3 col-sm-3 col-xs-3">
                  <Link className="nav-link" to="activities" spy={true} smooth={true} offset={-50} duration={500}>
                    Activități
                  </Link>
                </li>
                <li className="nav-item col-lg-3 col-md-3 col-sm-3 col-xs-3">
                  <Link className="nav-link" to="gallery" spy={true} smooth={true} offset={-50} duration={500}>
                    Hartă
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <p>&copy; Casa Bradeana 2019</p>
        </div>
      </div>
    </div>
  );
}


export default Footer;