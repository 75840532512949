/*
 * Base Google Map example
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shouldPureComponentUpdate from 'react-pure-render/function';

import GoogleMap from 'google-map-react';
import Places from './Places';

import { Link, animateScroll as scroll } from "react-scroll";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faAddressBook, faPhoneSquare, faMapMarkedAlt, faEnvelope, faBed, faHiking, faSkiing, faMountain, faSwimmer, faWater, faFish } from '@fortawesome/free-solid-svg-icons';

function createMapOptions(maps) {
    // next props are exposed at maps
    // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
    // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
    // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
    // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
    // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
    return {
        zoomControlOptions: {
            position: maps.ControlPosition.RIGHT_CENTER,
            style: maps.ZoomControlStyle.SMALL
        },
        mapTypeControlOptions: {
            position: maps.ControlPosition.TOP_RIGHT
        },
        mapTypeControl: true
    };
}

export default class GMap extends Component {
    static propTypes = {
        center: PropTypes.array,
        zoom: PropTypes.number,
        greatPlaceCoords: PropTypes.any
    };

    static defaultProps = {
        center: [46.447439, 22.964290],
        zoom: 12
    };

    shouldComponentUpdate = shouldPureComponentUpdate;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="row" id="gmap">
                <div className="col-lg-12 gmap">
                    <GoogleMap
                        apiKey={'AIzaSyCK-JhWtTXcupqajztSzc8fmp1YoFysMXc'}
                        center={this.props.center}
                        zoom={this.props.zoom}
                        options={createMapOptions}>
                        <Places lat={46.456473} lng={22.974952} icon={faBed} isMain={true}/>
                        <Places lat={46.494718} lng={22.812343} icon={faHiking}/>
                        <Places lat={46.513829} lng={22.676434} icon={faSkiing}/>
                        <Places lat={46.511034} lng={22.670201} icon={faSkiing}/>  
                        <Places lat={46.524245} lng={22.652907} icon={faHiking}/> 
                        <Places lat={46.441095} lng={22.688785} icon={faMountain}/>
                        <Places lat={46.469033} lng={22.837793} icon={faHiking}/>     
                        <Places lat={46.564689} lng={22.701513} icon={faHiking}/>    
                        <Places lat={46.455864} lng={22.937098} icon={faWater}/>                        
                    </GoogleMap>


                    <div className="container-fluid footer">
                        <div className="row">
                            <div className="col-lg-1"></div>
                            <div className="col-lg-10">
                                <ul className="f-contact">
                                    <li>
                                        <a href="https://www.facebook.com/" className="icon-text">
                                            <FontAwesomeIcon icon={faFacebook} /><span>CasaBradeana</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="icon-text">
                                            <FontAwesomeIcon icon={faAddressBook} /><span>Persoana de contact:</span><span>Iuliana Scrob</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="icon-text">
                                            <FontAwesomeIcon icon={faPhoneSquare} /><span>Telefon:</span><span>0740.270.243</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="icon-text">
                                            <FontAwesomeIcon icon={faMapMarkedAlt} /><span>Adresa:</span><span>Strada Principala, nr. 7, Albac, judetul Alba</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="icon-text">
                                            <FontAwesomeIcon icon={faEnvelope} /><span>E-Mail:</span> <span> casa.bradeana@gmail.com</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <p>&copy; Casa Bradeana 2019</p>
                        </div>
                    </div>
                    
                </div>
            </div>


        );
    }
}