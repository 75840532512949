import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faAsterisk } from '@fortawesome/free-solid-svg-icons'
//import Gallery from '../ImageGallery/Gallery'
import Gallery from 'react-image-gallery';
//import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css'

class Services extends React.Component {
	/* constructor() {
		super();
		this.state = {		  
			gallery: null,
			rooms: [
				{
					original: '/images/rooms/313FamilyEvents 163.jpg'
				},
				{
					original: '/images/rooms/313FamilyEvents 169.jpg'
				},
				{
					original: '/images/rooms/313FamilyEvents 170.jpg'
				},
				{
					original: '/images/rooms/313FamilyEvents 175.jpg'
				},
				{
					original: '/images/rooms/313FamilyEvents 178.jpg'
				},
				{
					original: '/images/rooms/313FamilyEvents 186.jpg'
				},
				{
					original: '/images/rooms/313FamilyEvents 187.jpg'
				},
				{
					original: '/images/rooms/313FamilyEvents 190.jpg'
				},
				{
					original: '/images/rooms/313FamilyEvents 206.jpg'
				},
				{
					original: '/images/rooms/313FamilyEvents 208.jpg'
				},
				{
					original: '/images/rooms/313FamilyEvents 214.jpg'
				},
				{
					original: '/images/rooms/313FamilyEvents 218.jpg'
				},
				{
					original: '/images/rooms/313FamilyEvents 223.jpg'
				},
				{
					original: '/images/rooms/313FamilyEvents 226.jpg'
				},
				{
					original: '/images/rooms/313FamilyEvents 229.jpg'
				},
				{
					original: '/images/rooms/313FamilyEvents 232.jpg'
				},
				{
					original: '/images/rooms/313FamilyEvents 236.jpg'
				},
				{
					original: '/images/rooms/313FamilyEvents 239.jpg'
				},
				{
					original: '/images/rooms/313FamilyEvents 240.jpg'
				},
				{
					original: '/images/rooms/313FamilyEvents 242.jpg'
				},
				{
					original: '/images/rooms/313FamilyEvents 248.jpg'
				},
				{
					original: '/images/rooms/313FamilyEvents 251.jpg'
				},
				{
					original: '/images/rooms/313FamilyEvents 267.jpg'
				},
				{
					original: '/images/rooms/313FamilyEvents 269.jpg'
				},
				{
					original: '/images/rooms/313FamilyEvents 276.jpg'
				},
				{
					original: '/images/rooms/313FamilyEvents 285.jpg'
				},
				{
					original: '/images/rooms/313FamilyEvents 287.jpg'
				},
				{
					original: '/images/rooms/313FamilyEvents 291.jpg'
				}
			],
			food: [{
				original: '/images/food/food1.jpg'
			},
			{
				original: '/images/food/food2.jpg'
			}],
			extra: [{
				original: '/images/activities/view1.jpg'
			}]
		};

	}

	showGallery(){
		if(this.state && this.state.gallery){
			this.state.gallery.fullScreen();
		}
	} */

	constructor(props) {
		super(props);

		this.state = {
			isFullscreen: false,
			rooms: null,
			food: null,
			extra: null
		}
	}

	showGallery(elem) {
		if (elem && this.state) {
			this.state[elem].props.showNav = true;
			this.state[elem].props.showFullscreenButton = true;
			this.state[elem].fullScreen();
			this.state[elem].pause();
		}
	}

	exit(elem,fullscreen){
		if(!fullscreen){
			this.state[elem].props.showNav = false;
			this.state[elem].props.showFullscreenButton = false;
			
			if(this.state && elem === 'rooms'){
				this.state[elem].play();
			}			
		}		
	}


	render() {
		return (
			<div className="container services">
				<div className="row" id="services">
					<div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
						<div class="row">
							<div class="col-xs">
								<div id="gallery">
									<Gallery items={ROOMS}
										showNav={false}
										showPlayButton={false}
										showFullscreenButton={false}
										showThumbnails={false}
										infinite={true}
										autoPlay={true}
										slideInterval={3000}
										ref={el => (this.state.rooms = el)}	
										onScreenChange={this.exit.bind(this,'rooms')}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
						<div class="content-info">
							<h1>Cazare și facilități</h1>
							<ul>
								<li>
									<FontAwesomeIcon className="font-awesome" icon={faCheck} />
									<span>Capacitate 12 locuri</span>
								</li>
								<li>
									<FontAwesomeIcon className="font-awesome" icon={faCheck} />
									<span>Sală de mese deservită de baie și bucătărie</span>
								</li>
								<li>
									<FontAwesomeIcon className="font-awesome" icon={faCheck} />
									<span>Teresă cu vedere asupra pârâului Valea Arăzii</span>
								</li>
								<li>
									<FontAwesomeIcon className="font-awesome" icon={faCheck} />
									<span>Loc de parcare și internet WIFI</span>
								</li>
								<li>
									<FontAwesomeIcon className="font-awesome" icon={faCheck} />
									Prețul unei camere duble: <span className="bold">140 RON</span> <span className="text-small">pe noapte</span>
								</li>
								<li>
									<FontAwesomeIcon className="font-awesome" icon={faCheck} />
									Prețul apartamentament: <span className="bold">200 RON</span> <span className="text-small">pe noapte</span>
								</li>
								<li>
									<FontAwesomeIcon className="font-awesome" icon={faCheck} />
									Micul dejun: <span className="bold">30 RON / persoană</span>
								</li>
								<div class="collapse" id="collapseExample">
									<ul>
										<li>
											<FontAwesomeIcon className="font-awesome" icon={faCheck} />
											<span>Camera 1 este situată în partea din spate către pădure,are un pat matrimonial,un dulap cu 2 uși, 2
											noptiere, măsuță și 2 tabureți, oglindă, cuier, televizor LCD si dispune de balcon, cu masă și scaune de
											lemn de unde se poate admira Vârful Cerbu.
									</span>
										</li>
										<li>
											<FontAwesomeIcon className="font-awesome" icon={faCheck} />
											<span>Camera 2 are un pat matrimonial un dulap cu 2 uși, 2 noptiere, măsuță și 2 tabureți, oglindă, cuier,
											televizor LCD, și este cu vedere spre pădure.
									</span>
										</li>
										<li>
											<FontAwesomeIcon className="font-awesome" icon={faCheck} />
											<span>Camera 3 este tot cu vedere spre pădure, dotată cu 2 paturi simple ce se pot uni la nevoie, un dulap
											cu 2 uși, 2 noptiere, măsuță și 2 tabureți, oglindă, cuier, televizor LCD.
									</span>
										</li>
										<li>
											<FontAwesomeIcon className="font-awesome" icon={faCheck} />
											<span>Camera 4 Camera 3 este tot cu vedere spre pădure, dotată cu 2 paturi simple ce se pot uni la nevoie,
											un dulap cu 2 uși, 2 noptiere, măsuță și 2 tabureți, oglindă, cuier, televizor LCD și are balcon unde
											se află o masă si 2 scaune.
									</span>
										</li>
										<li>
											<FontAwesomeIcon className="font-awesome" icon={faCheck} />
											<span>Camera 5 este un apartament destinat familiilor cu 1-2 copii, format din cameră cu pat matrimonial,
											dulap cu două uși, 2 noptiere, măsuță și tabureți, cuier, oglindă si o altă cameră mai mică cu o
											canapea extensibilă, o măsuță si 2 tabureți. Camerele sunt deservite de o baie.
									</span>
										</li>
										<li>
											<FontAwesomeIcon className="font-awesome" icon={faCheck} />
											<span>Camera 6 este amenajată într-un stil rustic si are intrare separată de pe terasa exterioară. Este dotată
											cu 2 paturi simple ce se pot uni la nevoie, 2 noptiere, dulap cu o ușă, cuier si oglindă. Are baie
											proprie.
									</span>
										</li>
									</ul>
								</div>
							</ul>
							<p class="buttons">
								<a class="btn btn-primary" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
									Detalii Camere
								  </a>
								<button class="btn btn-primary" type="button" onClick={this.showGallery.bind(this, 'rooms')}>
									Galerie Foto
								</button>
							</p>
						</div>
					</div>
				</div>
				<div className="icon-vertical-divider"></div>
				<div className="row">
					<div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
						<div class="content-info left">
							<h1>Mic dejun</h1>
							<ul>
								<li>
									<FontAwesomeIcon className="font-awesome" icon={faCheck} />
									<span>Bucate tradiționale, gătite din ingrediente BIO</span>
								</li>
								<li>
									<FontAwesomeIcon className="font-awesome" icon={faCheck} />
									<span>Mic dejun, prânz sau cină(la cerere)</span>
								</li>
								<li>
									<FontAwesomeIcon className="font-awesome" icon={faCheck} />
									<span>Mese tradiționale de Paște, Crăciun, Anul Nou</span>
								</li>
								<li>
									<FontAwesomeIcon className="font-awesome" icon={faCheck} />
									<span>Produsele provin din gospodăria proprie</span>
								</li>
							</ul>
							<p class="buttons">
								<button class="btn btn-primary" type="button" onClick={this.showGallery.bind(this, 'food')}>
									Galerie Foto
								</button>
							</p>
						</div>
					</div>
					<div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
						<div class="row">
							<div className="col-xs">
								<div id="gallery2">
									<Gallery items={FOOD} 
									showPlayButton={false} 
									showNav={false} 
									showFullscreenButton={false} 
									showThumbnails={false} 
									infinite={true} 
									autoPlay={false} 
									ref={el => (this.state.food = el)}	
									onScreenChange={this.exit.bind(this,'food')}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="icon-vertical-divider"></div>
				<div className="row" id="activities">
					<div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
						<div className="row">
							<div className="col-xs">
								<Gallery items={EXTRA} 
								showPlayButton={false} 
								showNav={false} 
								showFullscreenButton={false} 
								showThumbnails={false} 
								infinite={true} 
								autoPlay={false} 
								ref={el => (this.state.extra = el)}	
								onScreenChange={this.exit.bind(this,'extra')}
								/>
							</div>
						</div>
					</div>
					<div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
						<div class="content-info">
							<h1>Activități</h1>
							<ul>
								<li>
									<FontAwesomeIcon className="font-awesome" icon={faCheck} />
									<span>Excursie stână oi, sus pe munte, degustat branză(la cerere)</span>
								</li>
								<li>
									<FontAwesomeIcon className="font-awesome" icon={faCheck} />
									<span>Biciclete pentru plimbări de o zi</span>
								</li>
								<li>
									<FontAwesomeIcon className="font-awesome" icon={faCheck} />
									<span>Drumeții eg. Ghețarul Scărișoara, Targul de fete de la Muntele Găina</span>
								</li>
								<li>
									<FontAwesomeIcon className="font-awesome" icon={faCheck} />
									<span>Pescuit păstrav, scăldat in Arieș</span>
								</li>
								<li>
									<FontAwesomeIcon className="font-awesome" icon={faCheck} />
									<span>Cules afine sau bureți de pădure</span>
								</li>
								<li>
									<FontAwesomeIcon className="font-awesome" icon={faCheck} />
									<span>Olărit cu meșterul Costica(la cerere) și povești la gura sobei</span>
								</li>
							</ul>
							<p class="buttons">
								<button class="btn btn-primary" type="button" onClick={this.showGallery.bind(this, 'extra')}>
									Galerie Foto
								</button>
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const ROOMS = [
	{
		original: '/images/rooms/313FamilyEvents 163.jpg'
	},
	{
		original: '/images/rooms/313FamilyEvents 169.jpg'
	},
	{
		original: '/images/rooms/313FamilyEvents 170.jpg'
	},
	{
		original: '/images/rooms/313FamilyEvents 175.jpg'
	},
	{
		original: '/images/rooms/313FamilyEvents 178.jpg'
	},
	{
		original: '/images/rooms/313FamilyEvents 186.jpg'
	},
	{
		original: '/images/rooms/313FamilyEvents 187.jpg'
	},
	{
		original: '/images/rooms/313FamilyEvents 190.jpg'
	},
	{
		original: '/images/rooms/313FamilyEvents 206.jpg'
	},
	{
		original: '/images/rooms/313FamilyEvents 208.jpg'
	},
	{
		original: '/images/rooms/313FamilyEvents 214.jpg'
	},
	{
		original: '/images/rooms/313FamilyEvents 218.jpg'
	},
	{
		original: '/images/rooms/313FamilyEvents 223.jpg'
	},
	{
		original: '/images/rooms/313FamilyEvents 226.jpg'
	},
	{
		original: '/images/rooms/313FamilyEvents 229.jpg'
	},
	{
		original: '/images/rooms/313FamilyEvents 232.jpg'
	},
	{
		original: '/images/rooms/313FamilyEvents 236.jpg'
	},
	{
		original: '/images/rooms/313FamilyEvents 239.jpg'
	},
	{
		original: '/images/rooms/313FamilyEvents 240.jpg'
	},
	{
		original: '/images/rooms/313FamilyEvents 242.jpg'
	},
	{
		original: '/images/rooms/313FamilyEvents 248.jpg'
	},
	{
		original: '/images/rooms/313FamilyEvents 251.jpg'
	},
	{
		original: '/images/rooms/313FamilyEvents 267.jpg'
	},
	{
		original: '/images/rooms/313FamilyEvents 269.jpg'
	},
	{
		original: '/images/rooms/313FamilyEvents 276.jpg'
	},
	{
		original: '/images/rooms/313FamilyEvents 285.jpg'
	},
	{
		original: '/images/rooms/313FamilyEvents 287.jpg'
	},
	{
		original: '/images/rooms/313FamilyEvents 291.jpg'
	}
];

const FOOD = [{
	original: '/images/food/food1.jpg'
},
{
	original: '/images/food/food2.jpg'
}];

const EXTRA = [{
	original: '/images/activities/view1.jpg'
}];

export { ROOMS, FOOD, EXTRA };
export default Services;